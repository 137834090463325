<template>
  <div class="home">
    <Navigation />
    <div class="container">
      <h4>Upgrade to scheduled posting!</h4>
      <!-- <div class="row">
        <div id="servicebot-subscription-portal"></div>
      </div> -->

         <stripe-pricing-table 
          v-bind="[{'customer-email' : customerEmail},{'publishable-key': publishableKey},{'pricing-table-id': pricingTableKey},{'client-refrence-id': tenantId}]"
        >
        </stripe-pricing-table>
    </div>
  </div>
</template>
 
<script>
import Navigation from "@/components/Navigation.vue";
export default {
  components: {
    Navigation
  },
  data: () => ({
    first: null,
    last: null,
    publishableKey: process.env.VUE_APP_STRIPE_KEY,
    pricingTableKey: process.env.VUE_APP_STRIPE_PRICING_TABLE_ID,
    items: [
      {
        plan: process.env.VUE_APP_STRIPE_PLAN_MONTHLY,
        quantity: 1
      }
    ],
    email: null,
    serviceBotId: process.env.VUE_APP_SERVICEBOT_ID,
    hmac: null,
    
  }),
  created() {
    //this.init(this.loadServiceBot);
    this.loadServiceBot();
    this.email = this.$auth.user.email;
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    async loadServiceBot() {
      const hmac = this.$auth.user["https://theagentnest.com/hmac"];
      const email = this.$auth.user.email;
      window.servicebotSettings = {
        servicebot_id: this.serviceBotId,
        type: "Portal",
        service: "Agent Nest Portal",
        options: {
          hideCancelDropdown: true,
          debug: true,
          behavior: {
            subscription_portal: {
              showChangePlan: true
            }
          }
        },
        handleResponse: async function(payload) {
          console.log("Event ", payload.event);
          console.log("Response Object ", payload.response);
          //Redirect on Signup example
          if (payload.event == "post_change_plan") {
            window.location.href = "/scheduled/getting-started";
          }
        },
        email: email,
        hash: hmac
      };
      (function() {
        var s = document.createElement("script");
        s.src =
          "https://js.servicebot.io/embeds/servicebot-billing-settings-embed.js";
        s.async = true;
        s.type = "text/javascript";
        //var x = document.getElementsByTagName("script")[0];
        //x.parentNode.insertBefore(s, x);
        document.head.appendChild(s);
      })();
    }
  }
};
</script> 
<style scoped>
.logout {
  margin-left: 15px;
  background-color: #f19953;
  border-color: #f19953;
}
.cancel {
  margin-left: 15px;
  background-color: #ed6a5e;
  border-color: #ed6a5e;
}
.button-row {
  margin-bottom: 25px;
}
#servicebot-subscription-portal {
  width: 90%;
}
</style>